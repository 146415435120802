<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { i18n } from "vue-lang-router";

export default {
    name: 'photo',

    data() {
        return {
            name: '',
            email: '',
        };
    },

    created() {
        document.title = "Tempus Bildbyrå – ett bildarkiv för historia!";
    },

    computed: {
        ...mapGetters(['isLoading']),

    },

    methods: {
        send() {
            if (this.name == '' || this.email == '') {
                alert('Please complete all fields');
                return;
            }
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(this.email)) {
                alert('Email field must have email form');
                return;
            }
            axios.post(`${process.env.VUE_APP_BACKEND_URL}/api/newsletter/save`,
                { name: this.name, email: this.email }).then(() => {
                    this.name = "";
                    this.email = "";
                    alert('Saved');
                });
        },
    },

    components: {},
};
</script>

<template>
    <div class="illustration-info-container">
        <div class="illustration-info">
            <div class="content">
                <div class="header p-4">
                    <h1 class="text-3xl">Tempus Bildbyrå – ett bildarkiv för historia!</h1>
                </div>
                <br>
                <div class="content-paragraph">
                   Tempus Bildbyrå levererar digitala bilder till bokförlag, tidskrifter, museer, reklambyråer och alla andra som har behov av dokumentära bilder inom historia. Vår specialitet är svartvita illustrationer ur böcker och tidskrifter huvudsakligen från perioden 1830–1900. Vi scannar bilderna och avlägsnar fläckar och pappersfärg digitalt, varpå de registreras i vår databas.
                   <br/><br/>
                   Arkivet omfattar omkring en miljon bilder i antika böcker. De bilder som finns tillgängliga i databasen här på webbplatsen är således bara en liten bråkdel, men digitaliseringen av nya bilder fortskrider oförtrutet. Om du inte hittar ett motiv som du söker är du välkommen att <a href="kontakt">kontakta oss</a>. Vi hjälper gärna till med efterforskning och anskaffning av bilder – alltifrån enstaka förfrågningar till fullödiga bildredaktörsuppdrag.
                </div>

                <div class="content-paragraph borderedImage">
                    <img src="@/assets/lillustration.jpg" alt="Tempus Bildbyrås bildarkiv innehåller bilder ur antika böcker och tidskrifter. Här ses ett uppslag ur franska tidskriften">
                    <p>Tempus Bildbyrås bildarkiv innehåller bilder ur antika böcker och tidskrifter. Här ses ett uppslag ur franska tidskriften</p>
                </div>

                 <div class="content-paragraph">
                    Tempus Bildbyrå är alltså specialiserad på historia. Det innebär inte bara att det rör sig om gamla bilder, 
                    utan också att bilderna sätts in i ett historiskt sammanhang. Vad är då historia? Det är förstås allt som har hänt före nuet, 
                    men historia är också berättandet och ordnandet av det förflutna. Det som gör vår bildbyrå unik 
                    är att presentationen av bilderna tar hänsyn till historiskt sammanhang.
                    Vi har tagit fasta särskilt på <i>ordnandet</i>, det vill säga att klassificera fenomen och 
                    sätta in dem i ett sammanhang. Detta underlättar för dig när du letar efter bilder inom ett särskilt tema.
                    <br/><br/>
                    Tempus Bildbyrå erbjuder endast bilder vilkas upphovspersoner är avlidna sedan mer än sjuttio år. Det innebär att den ekonomiska upphovsrätten har upphört och att upphovspersonens ättlingar inte längre har rätt till ersättning när någon använder bilden ifråga.
                </div>
                 <div class="content-paragraph">
                        Läs mer:
                        <ul>
                           <li><a href="vanliga-fraagor">Vanliga frågor</a></li>
                           <li><a href=" intervju-med-bildbyraans-aegare">Intervju med bildbyråns ägare</a></li>
                        </ul>
                    </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.illustration-info-container {
    text-align: left;
    display: inline-block;
    background-color: rgba(0, 0, 0, 0.05);

    .illustration-info {
        margin: 0 25%;
        // height: 100vh;
        background-color: #fff;

        @media only screen and (max-width: 992px) {
            margin: 0 20px;
            width: 100%;
            height: 100%;
        }

        @media only screen and (max-width: 600px) {
            width: auto;
        }

        // @media screen and (max-width: $bp-mobile) {
        //   width: 100%;
        //   margin: 0;
        // }

        .header {
            span {
                margin: 0 1em;
                cursor: pointer;
            }

            h3 {
                padding: 1em;
            }
        }

        .content {
            padding: 1em;

            h1 {
                font-size: 30px;
                font-weight: 500;
                line-height: 36px;
            }

            .content-paragraph {
                font-size: 16px;
                font-weight: 500;
                line-height: 29px;

                ul{
                    list-style-type: disc;
                    padding-left: 20px;
                }
                
                a{
                    text-decoration: underline;
                    color: #1c1c68;
                }
            }

            .borderedImage{
                border: 1px solid gray;
                text-align: center;
                font-size: 14px;
            }

            div {
                padding: 1em;
            }

            .about {
                p {
                    margin: 0;
                    font-size: 0.9em;
                    line-height: 1.4em;
                }
            }

            .category {
                div {
                    padding: 0;
                    margin: 1em 0;

                    &:last-of-type {
                        margin-bottom: 0;
                        border-bottom: none;
                    }
                }
            }

            .description {
                background-color: #dfe6e9;
                padding-left: 0;
                padding-right: 0;
            }

            .tags {
                display: block;

                .badge {
                    display: inline-block;
                    font-size: 0.75em;
                    padding: 0.5em 0.75em;
                    border-radius: 5px;
                    color: #fff;
                    margin: 0 0.5em 0.5em 0;
                    background-color: #1c1c68;
                }
            }
        }
    }


}
</style>
